import { type FunctionComponent } from 'react';
import { Link } from '../../../../../components/common-components/link/link.component';
import { VideoCard } from '../../../../../components/common-components/video-card/video-card.component';
import { RichTextContent } from '../../../../../components/content-components/rich-text/rich-text-content.component';
import { CloudinaryImage } from '../../../../../components/images/cloudinary-image/cloudinary-image.component';
import { isContentRichText, isGeneralGroupContent } from '../../../../../helpers/category-helper/category.helper';
import { type CloudinaryOptions } from '../../../../../helpers/cloudinary-helper/cloudinary-helper';
import { type General, type GeneralGroupContent, type GeneralImage, type GeneralVideo } from '../../../../../types/content.types';

type GeneralContentProps = {
	generalContentData: General[];
};

export const GeneralContent: FunctionComponent<GeneralContentProps> = ({ generalContentData }) => {
	return (
		<>
			{generalContentData.map((generalItem, index) =>
				isContentRichText(generalItem) && generalItem.value ? (
					<RichTextContent key={index} className={'mt3 lh-copy'} rawHtml={generalItem.value} />
				) : isGeneralGroupContent(generalItem) ? (
					<GeneralContentItems key={index} generalGroupItems={generalItem.items} />
				) : null
			)}
		</>
	);
};

type GeneralContentItemsProps = {
	generalGroupItems: GeneralGroupContent['items'];
};

export const GeneralContentItems: FunctionComponent<GeneralContentItemsProps> = ({ generalGroupItems }) => {
	return (
		<div className="flex justify-around items-start">
			{generalGroupItems.length &&
				generalGroupItems.map((visualItem) =>
					visualItem.__typename === 'GeneralImageItem' && visualItem.image ? (
						<ImageItem key={visualItem.image.id} image={visualItem} itemsCount={generalGroupItems.length} />
					) : visualItem.__typename === 'GeneralVideoItem' && visualItem.video ? (
						<VideoItem key={visualItem.video.id} video={visualItem} />
					) : null
				)}
		</div>
	);
};

type ImageItemProps = {
	image: GeneralImage;
	itemsCount: number;
};
export const ImageItem: FunctionComponent<ImageItemProps> = ({ image, itemsCount }) => {
	const optionsForSingleImage: CloudinaryOptions = { crop: 'mpad', width: 1000, height: undefined };
	const optionsForTwoImages: CloudinaryOptions = { gravity: 'custom', crop: 'thumb', width: 326, height: 326 };
	const optionsForMultipleImages: CloudinaryOptions = { ...optionsForTwoImages, width: 210, height: 210 };
	return (
		<div>
			<Link url={image.url || ''}>
				<CloudinaryImage
					publicID={image.image.id}
					options={itemsCount === 1 ? optionsForSingleImage : itemsCount === 2 ? optionsForTwoImages : optionsForMultipleImages}
					description={image.image.description}
				/>
			</Link>
			{image.title && <p className="tc ma0">{image.title}</p>}
		</div>
	);
};

type VideoItemProps = {
	video: GeneralVideo;
};
export const VideoItem: FunctionComponent<VideoItemProps> = ({ video }) => {
	return (
		<div className="mv4 mh2 w-100 h-100 tc flex-auto">
			<VideoCard video={video.video} previewWidthPx={300} previewHeightPx={300} />
			{video.title && (
				<div className="ph1 lh-copy overflow-hidden" style={{ height: '4.5em', textOverflow: 'ellipsis' }}>
					{video.title}
				</div>
			)}
		</div>
	);
};
