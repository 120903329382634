import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';

const DefaultCategoryFields = gql`
	fragment DefaultCategoryFields on Category {
		id
		name
		url
		title
		image {
			description
			id
			imageType
		}
		hasProducts
	}
`;

export const CATEGORY = gql`
	query CategorySearch($categoryId: Int!, $featuredOnly: Boolean!) {
		category(id: $categoryId) {
			... on Category {
				...DefaultCategoryFields
				metaData {
					title
					description
				}
				subCategories(featuredOnly: $featuredOnly) {
					...DefaultCategoryFields
					isFeatured
				}
				breadcrumbs {
					name
					url
				}
				categoryContentOverride {
					baseCategory
					brand
					businessCategory
					categoryBrand {
						brandImage {
							...ImageFields
						}
						brandName
					}
				}
			}
			... on NotFoundError {
				message
			}
		}
	}
	${DefaultCategoryFields}
	${ImageFields}
`;
