import { type ReactElement, type FunctionComponent, type CSSProperties, type PropsWithChildren, cloneElement } from 'react';
import { type ServerAnalyticsInterface } from '../../../../../@types/build';
import { isString } from '../../../../utils/typeguards/primatives';
import { type CloudinaryOptions, generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { LinkButton } from '../../buttons/link-button/link-button.component';
import { MaybeLink } from '../../common-components/link/link.component';

type Heading = string | ReactElement<HTMLHeadingElement>;

type CallToAction = {
	name: string;
	url: string;
};

type Layout = 'full' | 'split' | 'square';

export type HeadingBannerProps = {
	heading: Heading;
	secondHeading?: Heading;
	bannerImageId: string;
	callToAction?: CallToAction;
	layout?: Layout;
	serverAnalytics?: ServerAnalyticsInterface;
};

export function applyStyleToHeading(heading: Heading, layout: Layout): ReactElement<HTMLHeadingElement> {
	const headingToRender = isString(heading) ? <h1>{heading}</h1> : heading;
	const tachyons = ['ma0'];
	switch (layout) {
		case 'split':
			tachyons.push('f6', 'f3-ns', 'theme-primary');
			break;
		case 'square':
			tachyons.push('f4', 'theme-white');
			break;
		default:
			tachyons.push('f1-l', 'f3-m', 'theme-white');
	}
	return cloneElement<HTMLHeadingElement>(headingToRender, { className: tachyons.join(' ') });
}

type HeadingsProps = {
	heading: ReactElement<HTMLHeadingElement>;
	subHeading?: ReactElement<HTMLHeadingElement>;
	layout: Layout;
};
const Headings: FunctionComponent<HeadingsProps> = ({ heading, subHeading, layout }) => {
	if (layout === 'square' && subHeading) {
		return (
			<div>
				{heading}
				{subHeading}
			</div>
		);
	} else {
		return (
			<>
				{heading}
				{subHeading}
			</>
		);
	}
};
export const HeadingBanner: FunctionComponent<HeadingBannerProps> = ({
	heading,
	secondHeading,
	bannerImageId,
	callToAction,
	layout = 'full',
	serverAnalytics
}) => {
	const headingToRender = applyStyleToHeading(heading, layout);
	const secondHeadingToRender = secondHeading ? applyStyleToHeading(secondHeading, layout) : undefined;

	const style: CSSProperties = generateStyles(layout, bannerImageId);
	const classNames = generateTachyons(layout);

	const buttonWidth = layout === 'square' ? '100%' : 'initial';
	return (
		<MaybeLink url={callToAction?.url} serverAnalytics={serverAnalytics}>
			<div style={style} className={classNames} data-testid="heading-banner">
				<TextWrapperLayout layout={layout}>
					<Headings heading={headingToRender} subHeading={secondHeadingToRender} layout="square" />
					{callToAction && (
						<span style={{ width: buttonWidth }} className="dn db-ns">
							<LinkButton url={callToAction.url}>{callToAction.name}</LinkButton>
						</span>
					)}
				</TextWrapperLayout>
			</div>
		</MaybeLink>
	);
};

type TextWrapperLayoutProps = {
	layout: Layout;
};
const TextWrapperLayout: FunctionComponent<PropsWithChildren<TextWrapperLayoutProps>> = ({ layout, children }) => {
	return layout === 'split' ? (
		<div className="w-30 b--theme-grey h-100 flex flex-column justify-around ph3">{children}</div>
	) : (
		<>{children}</>
	);
};

// generates appropriate css properties based on the layout
export function generateStyles(layout: Layout, publicID: string): React.CSSProperties {
	let backgroundImageDimensions: CloudinaryOptions;
	let backgroundPosition;

	switch (layout) {
		case 'split':
			backgroundImageDimensions = { width: 560, height: 227 };
			backgroundPosition = 'right';
			break;
		case 'square':
			backgroundImageDimensions = { width: 305, height: 208 };
			backgroundPosition = 'bottom';
			break;
		default:
			backgroundImageDimensions = { width: 1246, height: 208 };
			backgroundPosition = 'center';
	}

	const backgroundImageOptions: CloudinaryOptions = { crop: 'fill', gravity: 'face', ...backgroundImageDimensions };
	const backgroundImageURL = generateCloudinaryUrl(publicID, backgroundImageOptions);

	const backgroundSize = layout === 'split' ? '60%' : '100%';

	const aspectRatio = layout === 'square' ? '1/1' : '4/1';
	const maxWidth = layout === 'square' ? '305px' : '1246px';

	return {
		aspectRatio,
		backgroundImage: `url(${backgroundImageURL})`,
		backgroundSize,
		backgroundRepeat: 'no-repeat',
		backgroundPosition,
		maxWidth
	};
}
export function generateTachyons(layout: Layout): string {
	const tachyons = ['flex', 'flex-column'];
	switch (layout) {
		case 'split':
			tachyons.push('justify-around', 'ba', 'b--theme-grey-light');
			break;
		case 'square':
			tachyons.push('justify-between', 'bg-theme-primary-dark', 'items-center', 'tc', 'pa3');
			break;
		default:
			tachyons.push('justify-center', 'pa3');
	}
	return tachyons.join(' ');
}
